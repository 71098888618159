import React, {useEffect} from 'react'
import './Projekte.css'
import immolux from './../../pdf/immoluxabstract.pdf';

function Projekte() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  /*
  
<section id="gallery" className='gallery'>
  <div class="container">
    <div class="row">
      <div class="col-lg-4 mb-4">
      <div class="card">
          <img src="./images/immolux.png" alt="" class="card-img-top"/>
          <div class="card-body">
            <h5 class="card-title">hafnerjonas.ch</h5>
            <p class="card-text">
              React, 
              Backend: Node.js, Datenbankeinbindung (MySQL) Frontend: HMTL/ CSS, Bootstrap, JS
            </p>
            <a href="" class="btn btn-dark btn-sm">Abstract</a>
          </div>
          </div>
        </div>
      </div>
</div>
</section>
  */


  return (
    <>
            
      <section id="gallery" className='gallery'>
        <div class="container">
          <div class="row">
          <div class="col-lg-4 mb-4">
          <div class="card">
            <img src="./images/eventindustry.png" alt="" class="card-img-top"/>
            <div class="card-body">
              <h5 class="card-title">Eventindustry</h5>
              <p class="card-text">Dieses Projekt ist durch eine Anfrage von Freunden entstanden. 
              Während meiner Freizeit habe ich nach ihren wünschen und meinen Ideen eine Webseite gestaltet. 
              Die Webseite bestand zuerst aus HTML/ CSS und JS. Mittlerweile ist es eine ReactJS app.</p>
              <a href="https://eventindustry.netlify.app/" target="_blank" class="btn btn-dark btn-sm buttonspace">Demo</a>
            </div>
          </div>
          </div>
        <div class="col-lg-4 mb-4">
        <div class="card">
            <img src="./images/immolux.png" alt="" class="card-img-top"/>
            <div class="card-body">
              <h5 class="card-title">Immolux</h5>
              <p class="card-text">Im 304 ÜK haben wir als Team eine Luxusimmobilien-Webseite erstellt. 
              Mit PHP haben wir eine Datenbankverbindung erstellt, welche alle CRUD Operationen durchführen kann. 
              Angebote können erst erstellt werden, wenn sich ein Benutzer registriert und anschliessend einloggt.</p>
            <a href="https://bhorvi.bbc-projects.ch" target="_blank" class="btn btn-dark btn-sm buttonspace">Webseite</a>
              <a href={immolux} class="btn btn-dark btn-sm">Abstract</a>
            </div>
            </div>
          </div>
          <div class="col-lg-4 mb-4">
          <div class="card">
            <img src="./images/seekr.png" alt="" class="card-img-top"/>
            <div class="card-body">
              <h5 class="card-title">SEEKR</h5>
              <p class="card-text">
                Im Vorbereitungsmodul der IPA haben wir mit ReactJS, einer FBI-API und ReactSimpleMaps eine Webseite erstellt.
                Der Zweck der Webseite ist es, den Geburtsort der Verbrecher der Mostwanted Liste der FBI, auf einer Weltkarte visuell darzustellen.
                Zusätzlich werden in einer Tabelle noch weitere Personalien über den Verbrecher/in angegeben.
              </p>
              <a href="https://seekr.tk/" target="_blank" class="btn btn-dark btn-sm buttonspace">seekr.tk</a>
            </div>
          </div>
          </div>
        </div>
      </div>
      </section>


{/*
      <section id="gallery" className='gallery'>
        <div class="container">
          <div class="row">
          <div class="col-lg-4 mb-4">
          <div class="card">
            <img src="./images/m120.png" alt="" class="card-img-top"/>
            <div class="card-body">
              <h5 class="card-title">Benutzerschnittstellen Implementieren</h5>
              <p class="card-text">
                In der Schule haben wir im Modul 120 ein selbst gewähltes Projekt realisiert.
                Dort habe ich die Ergonomieanforderungen für eine Desktop-Applikation kennengelernt.
                Das Frontend wurde mit XAML und das Backend mit C# programmiert.
                Mit C# habe ich eine Datenbankverbindung erstellt. 
                Mein Projekt war eine Schülerdatenbank, in der via GUI alle CRUD-Operationen ausgefürt werden können.
              </p>
              <a href="https://eventindustry.netlify.app/" target="_blank" class="btn btn-dark btn-sm buttonspace">Demo</a>
            </div>
          </div>
         </div>
         </div>
         </div>
         </section>
*/}

    </>
  )
}

export default Projekte
