import React, {useState, useEffect} from 'react';
import Axios from 'axios';
import App from '../../App';

/*
import {submitData} from '../../App';
import {setUsersUsername} from '../../App';
import {setUsersPassword} from '../../App';
import {deleteUser} from '../../App';
import {usersList} from '../../App';
*/


function Registration() {
    const [usersUsername, setUsersUsername] = useState('');
    const [usersPassword, setUsersPassword] = useState('');
    const [usersList, setUsersList] = useState([]);

useEffect(()=>{
    Axios.get('http://localhost:3001/api/get').then((response) =>{
      setUsersList(response.data);
      //console.log(response.data);
    });
  }, []);

  /*
  useEffect(()=>{
    Axios.get('http://localhost:3001/api/check').then((response) =>{
      setCurrentUser(response.data);
      console.log(response.data);
      console.log("Das isch d Liste");
    });
  }, []);
  */


  const submitData = () => {
    Axios.post("http://localhost:3001/register", {
      username: usersUsername, /*Sending username and password*/
      password: usersPassword,
    });
      setUsersList([
        ...usersList, 
        {username: usersUsername, 
          password: usersPassword},
      ]);
    };



  const deleteUser = (name) => {
    Axios.delete(`http://localhost:3001/api/delete/${name}`);
  }






//var app = new App();


  return (
        <>
        

            <h1>Benutzer registrieren</h1>

            <div className='from'>
            <label>Benutzername:</label>
            <input type="text" name="username" onChange={(e) => {
                setUsersUsername(e.target.value)
            }}/>
            <label>Passwort:</label>
            <input type="text" name="password"onChange={(e) => {
                setUsersPassword(e.target.value)
            }}/>

            <button onClick={submitData}>Absenden</button>

            

            {usersList.map((val)=>{
                return(
                <>
                    <h2>Username: {val.username} | Password: {val.password}</h2>

                    <button onClick={() => {deleteUser(val.username)}}>Delete</button>
                    <input type="text" id="updateInput"/>
                    <button>Update</button>
                </>
                );
            })}
            </div>
        </>
  )
}

export default Registration
