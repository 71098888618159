import React, {useEffect} from 'react'

function Ferienjob() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <>
      
      
      <section className='img-text mb-5'>
        <div class="row align-items-center">
        <div class="col-50">
            <article className='articleimg'>
              <h1 className='titles'>Ufwärts</h1>
              <p className='subtitles'>In den langen Sommerferien oder in den Herbstferien eignet es sich gut 1-2 Wochen arbeiten zu gehen. 
              Um meine Ausgaben zu decken und unabhängiger/ selbstständiger sein zu können, ist der Ferienjob bei Ufwärts GmbH eine gute Lösung. 
              Ich bin froh, dass ich auch in andere Bereiche sehen konnte wie Landschaftsgärtner, Reinigungsfachkraft und Liegenschaftsservice.
              </p>

              <p className='subtitles'>
              Mit diesen Erfahrungen konnte ich mir auch klarer werden, dass Informatik für mich eine grössere Leidenschaft ist als andere Bereiche. 
              Zudem habe ich beim Ferienjob gelernt Verantwortung zu übernehmen und selbstständig seine Arbeit zu erledigen, aber auch im Team gut zusammenzuarbeiten.
              </p>
            </article>
          </div>
          <div class="col-50">
            <img className='sectionimg' src="./images/ufwartsgarten.jpg" alt="image"/>
          </div>
        </div>
      </section>


    </>
  )
}

export default Ferienjob
