import React, {useEffect} from 'react';
import './Lebenslauf.css';
import bmzeugnis from './../../pdf/bmzeugnis.pdf';
import gibbzeugnis from './../../pdf/gibbzeugnis.pdf'
import uks from './../../pdf/uks.pdf'
import lebenslauf from './../../pdf/lebenslauf.pdf'
import abacus from './../../pdf/abacusfibu.pdf'

/*
<object width="100vh" height="100vh" type="application/pdf" data="files/zeugnis.pdf"/> 
    <iframe src="files/zeugnis.pdf" width="90%" height="100px"></iframe>

    <embed width="90%" height="100" data="zeugnis.pdf" type="application/pdf" src="files/zeugnis.pdf"></embed>
*/


function Lebenslauf() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  /*href={bmzeugnis}  bei <a> vor <img>*/ 

  return (
    <>

    <h1 className='pdffirst text-center'>Lebenslauf</h1>
    <div className='h1line'></div>
    <table class="mt-5 tablepdf">
      <tr>
        <td className='td'>
          <p className='pdftext'>Lebenslauf</p>
        </td>
        <td>
          <a href={lebenslauf} target="_blank" rel="noreferrer">
            <img className='imgpdf' src='/images/pdf.png'></img>
          </a>
        </td>
      </tr>
    </table>

    <h1 className='mobilepdf text-center'>Zeugnisse</h1>
    <div className='h1line'></div>
    <table class="mt-5 tablepdf">
      <tr>
        <td className='td'>
          <p className='pdftext'>Bwd</p>
        </td>
        <td>
          <a href={bmzeugnis} target="_blank" rel="noreferrer">
            <img className='imgpdf' src='/images/pdf.png'></img>
          </a>
        </td>
      </tr>
    </table>

    <table class="mt-5 tablepdf">
      <tr>
        <td className='td'>
          <p className='pdftext'>Gibb</p>
        </td>
        <td>
          <a href={gibbzeugnis} target="_blank" rel="noreferrer">
            <img className='imgpdf' src='/images/pdf.png'></img>
          </a>
        </td>
      </tr>
    </table>

    <h1 className='mobilepdf text-center'>Überbetriebliche Kurse (ÜK)</h1>
    <div className='h1line'></div>
    <table class="mt-5 tablepdf">
      <tr>
        <td className='td'>
          <p className='pdftext'>ÜK inkl. Office</p>
        </td>
        <td>
          <a href={uks} target="_blank" rel="noreferrer">
            <img className='imgpdf' src='/images/pdf.png'></img>
          </a>
        </td>
      </tr>
    </table>

    <h1 className='mobilepdf text-center'>Sonstige</h1>
    <div className='h1line'></div>
    <table class="mt-5 mb-5 tablepdf">
      <tr>
        <td className='td'>
          <p className='pdftext'>Abacus Zertifikat</p>
        </td>
        <td>
          <a href={abacus} target="_blank" rel="noreferrer">
            <img className='imgpdf' src='/images/pdf.png'></img>
          </a>
        </td>
      </tr>
    </table>

    </>
  )
}

export default Lebenslauf
