import React, {useEffect} from 'react'
import './Impressum.css'


function Impressum() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

  return (
    <>
        <h1 className='impressspacing'>Urheberrechte</h1>
        <p className='impressspacing2'>
            Die Uhrheberrechte von allen Textinhalten, Dateien und einigen Bildern gehören ausschliesslich Jonas Hafner.
            Einige Bilder welche auf der Seite verwendet werden sind von Unsplash.
            Für die Verwendung der Textinhalten, Dateien und Bilder die Jonas Hafner gehören ist eine schriftliche Zustimmung des Urheberrechtsträgers im Voraus einzuholen.
        </p>

        <h1 className='impressspacing'>Haftungsausschluss</h1>
        <p className='impressspacing2'>
        Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs. 
        Es wird jegliche Verantwortung für solche Webseiten abgelehnt. 
        Der Zugriff und die Nutzung solcher Webseiten erfolgen auf eigene Gefahr des jeweiligen Nutzers.
        </p>
    </>
  )
}

export default Impressum
