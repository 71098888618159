//import logo from './logo.svg';
import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import {BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import Axios from 'axios';
import Home from './components/pages/Home';
import Hobbys from './components/pages/Hobbys';
import Registration from './components/pages/Registration';
import Lebenslauf from './components/pages/Lebenslauf';
import Ferienjob from './components/pages/Ferienjob';
import Projekte from './components/pages/Projekte';
import Impressum from './components/pages/Impressum';

/*import ScrollToTop from './components/ScrollToTop';*/

/*
export function submitData() {};
export function setUsersUsername() {};
export function setUsersPassword() {};
export function deleteUser() {};
export function usersList() {};
*/


//import Regstister from './Register';




function App() {
  const [usersUsername, setUsersUsername] = useState('');
  const [usersPassword, setUsersPassword] = useState('');
  const [usersList, setUsersList] = useState([]);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [loginStatus, setLoginStatus] = useState(false);

  const [loginMessage, setLoginMessage] = useState('');

  Axios.defaults.withCredentials = true;

  /*
  var config = {
    headers: ['Access-Control-Allow-Origin'] = '*'
  }
  */


  //const [currentUser, setCurrentUser] = useState([]);
  //const [usersId, setUsersId] = useState('');

/* Würd gah aber sött nid jede User bi dr Netzwerkanalyse gseh
  useEffect(()=>{
    Axios.get('https://hafnerjonas.herokuapp.com/api/get').then((response) =>{
      setUsersList(response.data);
      //console.log(response.data);
    });
  }, []);
  */

  /*
  useEffect(()=>{
    Axios.get('http://localhost:3001/api/check').then((response) =>{
      setCurrentUser(response.data);
      console.log(response.data);
      console.log("Das isch d Liste");
    });
  }, []);
  */


  const submitData = () => {
    Axios.post("https://hafnerjonas.herokuapp.com/register", {
      username: usersUsername, /*Sending username and password*/
      password: usersPassword,
    });
      setUsersList([
        ...usersList, 
        {username: usersUsername, 
          password: usersPassword},
      ]);
    };



  const deleteUser = (name) => {
    Axios.delete(`https://hafnerjonas.herokuapp.com/api/delete/${name}`);
  }

  const login = () =>{
    Axios.post("https://hafnerjonas.herokuapp.com/login", {
      username: username, /*Sending username and password*/
      password: password
    }).then((response)=>{   /*zwischen . und then war eine Lücke, ich denke hier wird der Cookie hinzugefügt*/
      console.log(response);
      if (!response.data.auth){
        setLoginStatus(false);
        setLoginMessage(response.data.message);
      }
      else{
        console.log(response.data);
        localStorage.setItem("token", response.data.token);  /*Wenn der Token gelöscht ist man nicht angemeldet via überprüfung von isUserAuth*/
        setLoginStatus(true);
      }
    });
  };

  const userAuthenticated = () => {
    Axios.get("https://hafnerjonas.herokuapp.com/isUserAuth", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      console.log(response);
      
      
      if (response.data.message == "You failed to authenticate"){
        console.log("Beendung")
        //setLoginStatus(false);
      }
      /*
      else{
        setLoginStatus(false);
        localStorage.removeItem("token");
      }
      */
      
    });
  };

  const userAuthenticated2 = () => {
    //console.log(localStorage.getItem("token"));
    Axios.get("https://hafnerjonas.herokuapp.com/isUserAuth", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    })
  };

  useEffect(()=>{
    Axios.get("https://hafnerjonas.herokuapp.com/login").then((response) =>{
      //console.log(response);
      if (response.data.loggedIn == true){
        //console.log(response.data.user[0].username);
        //setLoginStatus(response.data.user[0].username);
        //console.log("useeff wots wider aschalte");
        setLoginStatus(true);
      }
      else{
        setLoginStatus(false);
      }
      
    });
  }, []);

  /*
  useEffect(()=>{
    if(localStorage.getItem("token") == null){
      console.log("es geit");
      setLoginStatus(false);
    }
    else{
      console.log("es geit nid");
    }
    
  }, []);
  */
  


  const logout = () =>{
    
    //localStorage.removeItem("token");
    /*
    Axios.get("http://localhost:3001/logout").then((response) =>{
      //console.log(response);
      if (response.data.loggedIn == true){
        setLoginStatus(response.data.user[0].username);
      }
      
    })*/
    
    //Axios.post("http://localhost:3001/logout", {})
    //const item = localStorage.getItem("token");
    //console.log(item);
    
    //localStorage.setItem("token", response.data.token);
    //setLoginStatus(false);
    
  }

  //const [changeInput, setChangeInput] = useState(false);





  /*{!loginStatus && (window.location.href = '/')}   Seite lädt sich immer neu*/

  return (
    <Router>
      <Navbar/>
        <Routes>
          {!loginStatus && ( <Route path="/" element={<Home/>}/> )}
          {!loginStatus && ( <Route path="/hobbys" element={<Hobbys/>}/> )}
          {loginStatus && ( <Route path="/dokumente" element={<Lebenslauf/>}/> )}
          {!loginStatus && ( <Route path="/ferienjob" element={<Ferienjob/>}/> )}
          {!loginStatus && ( <Route path="/projekte" element={<Projekte/>}/> )}

          {!loginStatus && ( <Route path="/impressum" element={<Impressum/>}/> )}
          
          
        </Routes>


        

        {loginStatus && (
        <>

        <div className='container margintop mb-5 d-flex justify-content-center'>
          
          <div className='from width'>
            <h1 className='mb-3'>Login</h1>
            
              <div class="form-group colum mb-2">
                <label class="col-sm-2 col-form-label">Benutzername</label>
                <div class="col-sm-10">
                  <input class="form-control minwidth" placeholder="Benutername" required type="text" minLength={2} name="username" onChange={(e) => {
                    setUsername(e.target.value)
                  }}/>
                </div>
              </div>
              <div class="form-group colum mb-4">
                <label class="col-sm-2 col-form-label">Passwort</label>
                <div class="col-sm-10">
                  <input class="form-control minwidth" placeholder="Passwort" required type="password" minLength={2} name="password" onChange={(e) => {
                    setPassword(e.target.value)
                  }}/>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-10">
                  <button onClick={login} class="btn btn-primary">Einloggen</button>
                </div>
              </div>
            
          </div>
        </div>

          <h2 className='text-center'>{loginMessage}</h2>

        </>
        )}
        
      
        
        {!loginStatus && (<Footer/>)}

    </Router>
  );
}

/*  {loginStatus && (<button onClick={logout}>Logout</button>)}

    {loginStatus && (<button onClick={userAuthenticated2}>Check if authenticated</button>)}*/




export default App;
