import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';


/*<img src="images/jonas.png" className='rounded mx-auto d-block mb-5' alt="Bild von Jonas"></img>*/

/*<img src="images/landschaft.jpg" className='img-fluid mainpic' alt="Wassertropfen"></img>*/
/*
 <div class="outer">
          <div class="middle">
            <div class="inner">
              <h1>The Content</h1>
              <p>Once upon a midnight dreary...</p>
            </div>
          </div>
        </div>
        */

        /*  Willkomenstext grösse: 1 Seite, Form 2 Ecken 2 Rund, innen Text, mit schwarzer border
        <div className='section1'>
          <div class="d-flex h-100">
              <div class="row justify-content-center align-self-center introduction">
                <p class="font-italic text-muted mb-0">Schön dass Sie sich entschieden haben meine Webseite zu besuchen!</p>
                <p class="font-italic text-muted mb-0">Ich werde mich Ihnen in den vier unten aufgelisteten Kathegorien vorstellen, bei Fragen wenden Sie sich gerne an mich:</p>
                <p class="font-italic text-muted mb-0">jonas.hafner@bwdbern.ch</p>
                <p class="font-italic text-muted mb-4">079 628 51 94</p>
              </div>
            </div>
        </div>
        */

function Home() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])



  return (
    <>
    <div className='overflow'>
              
        <div class="backimage"></div>

        <svg class="arrows">
							<path class="a1" d="M0 0 L30 32 L60 0"></path>
							<path class="a2" d="M0 20 L30 52 L60 20"></path>
							<path class="a3" d="M0 40 L30 72 L60 40"></path>
						</svg>


        <table className='desktopview'>
          <tr>
            <td> 
              <h1 className='mt-5 overtext text-center'>Jonas Hafner</h1>
              <p class="font-italic overtext2 text-center">Willkommen, schön dass Sie sich entschieden haben meine Webseite zu besuchen!</p>
            </td>
            
            <td className='placeholder'>&nbsp;</td>

            <td> 
              <img src="images/portrait.jpg" className='roundimg' alt="Bild von Jonas"></img>
            </td>
          </tr>
        </table>

        <div class="d-flex justify-content-center">
          <div className='mobileview'>
            <div className='d-flex-colum justify-content-md-center'>
            <h1 className='mt-5 overtext text-center'>Jonas Hafner</h1>
            <p class="font-italic overtext2 text-center">Willkommen, schön dass Sie sich entschieden haben meine Webseite zu besuchen!</p> 
            </div>
            <div className='d-flex-row justify-content-center text-center'>
            <img src="images/portrait.jpg" className='roundimg' alt="Bild von Jonas"></img>
            </div>
          </div>
        </div>



        

        <div class="cardmargin">
         
          <div class="row">
        
          <div class="col-lg-6 mb-3 mb-lg-2">
            <Link to='/dokumente'>
              <div class="hover hover-1 text-white rounded"><img src="images/lebenslaufzeugnis.jpg" alt=" "/>
                <div class="hover-overlay"></div>
                <div class="hover-1-content px-5 py-4">
                  <h3 class="hover-1-title text-uppercase font-weight-bold mb-0 fontborder"> <span class="font-weight-light">Lebenslauf/ Zeugnis</span></h3>
                  <p class="hover-1-description font-weight-light mb-0 fontborder">Lebenslauf & die Noten der Berufs/ BM-schule</p>
                </div>
              </div>
            </Link>
          </div>

            

            <div class="col-lg-6">
            <Link to='/hobbys'>
              <div class="hover hover-1 text-white rounded"><img src="images/hanteln.jpg" alt=""/>
                <div class="hover-overlay"></div>
                <div class="hover-1-content px-5 py-4">
                  <h3 class="hover-1-title text-uppercase font-weight-bold mb-0"> <span class="font-weight-light">Hobbys/ Interessen</span></h3>
                  <p class="hover-1-description font-weight-light mb-0">Informatik, Krafttraining und Reisen</p>
                </div>
              </div>
            </Link>
              
            </div>

            <div className='m-2'></div>

          

            <div class="col-lg-6 mb-3 mb-lg-2">
            <Link to='/projekte'>
              <div class="hover hover-1 text-white rounded"><img src="images/eventindustry.png" alt=""/>
                <div class="hover-overlay"></div>
                <div class="hover-1-content px-5 py-4">
                  <h3 class="hover-1-title text-uppercase font-weight-bold mb-0 fontborder"> <span class="font-weight-light">Projekte</span></h3>
                  <p class="hover-1-description font-weight-light mb-0 fontborder">Eventindustry, Interaktive Webseite, C# Desktop-Applikation</p>
                </div>
              </div>
            </Link>
            </div>

            <div class="col-lg-6">
            <Link to='/ferienjob'>
              <div class="hover hover-1 text-white rounded"><img src="images/reinigung.jpg" alt=""/>
                <div class="hover-overlay"></div>
                <div class="hover-1-content px-5 py-4">
                  <h3 class="hover-1-title text-uppercase font-weight-bold mb-0"> <span class="font-weight-light">Ferienjob</span></h3>
                  <p class="hover-1-description font-weight-light mb-0">Gartenbau, Reinigung, Umzug & Liegenschaftsservice bei Ufwärts GmbH</p>
                </div>
              </div>
            </Link>
            </div>

            <div className='m-5'></div>

          </div>
        </div>



      </div>
    </>
  )

}

export default Home
