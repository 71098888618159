import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import './Navbar.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const [navbar, setNavbar] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
      if (window.innerWidth <= 960) {
        setButton(false);
      } 
      else {
        setButton(true);
      }
    };
/*
    useEffect(()=>{

      if(click == true){
        setNavbar(true);
      }
    
      
    }, []);
    */


    window.addEventListener('resize', showButton);

    const changeBackground = () =>{
      if(window.scrollY >= 100){
        setNavbar(true);
      }
      else{
        setNavbar(false);
      }
    }

    window.addEventListener('scroll', changeBackground);


  return (
    <>
    <nav  id="overneath" className={navbar == true || click == true ? 'navbar navbar-expand-lg navbar-light bg-light fixed-top scrolled' : 'navbar navbar-expand-lg navbar-light bg-light fixed-top notscrolled'} name="navbar">
    <div className="container-fluid">
      <a className="navbar-brand" href="/">JHA</a>
      <div className={click ? 'text-dark navbar-toggler border-0' : 'text-dark navbar-toggler border-0 collapsed'} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded={click ? 'true' : 'false'} aria-label="Toggle navigation" onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
      </div>
      <div className={click ? 'collapse navbar-collapse show' : 'collapse navbar-collapse'} id="navbarNavDropdown">
        <ul className=" navbar-nav text-center">
          <li className="nav-item">
            <Link to='/' className='nav-link text-dark' onClick={closeMobileMenu}>
                Home
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/hobbys' className='nav-link text-dark' onClick={closeMobileMenu}>
                Interessen
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/dokumente' className='nav-link text-dark' onClick={closeMobileMenu}>
              Dokumente
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/projekte' className='nav-link text-dark' onClick={closeMobileMenu}>
              Projekte
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/ferienjob' className='nav-link text-dark' onClick={closeMobileMenu}>
              Ferienjob
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </nav>

    <nav id="underneath" className={navbar ? 'navbar navbar-expand-lg navbar-light bg-light fixed-top scrolled' : 'navbar navbar-expand-lg navbar-light bg-light fixed-top notscrolled'} name="navbar">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
          <a className="navbar-brand" href="/">JHA</a>
              </div>
              <div className="col-sm-6">
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                  <ul className=" navbar-nav text-center">
                    <li className="nav-item">
                      <Link to='/' className='nav-link text-dark' onClick={closeMobileMenu}>
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to='/hobbys' className='nav-link text-dark' onClick={closeMobileMenu}>
                        Interessen
                      </Link>
                    </li>
                    <li className="nav-item">
                    <Link to='/dokumente' className='nav-link text-dark' onClick={closeMobileMenu}>
                      Dokumente
                    </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <a className="nav-link text-dark dropdown-toggle" onClick={setNavbar} href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Weitere
                      </a>
                      <ul className="dropdown-menu bg-light border-0" aria-labelledby="navbarDropdownMenuLink">
                        <li>
                        <Link to='/projekte' className='dropdown-item text-center' onClick={closeMobileMenu}>
                          Projekte
                        </Link> 
                        </li>
                        <li>
                        <Link to='/ferienjob' className='dropdown-item text-center' onClick={closeMobileMenu}>
                          Ferienjob
                        </Link> 
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </div>

        </div>
      </nav>
      </>
  );
}

export default Navbar;
