import React, {useEffect} from 'react';
import './Hobbys.css';



function Hobbys() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])



  return (
    <>

      <section className='img-text'>
        <div class="row align-items-center">

        <div class="col-50 mobileimg">
            <article className='articleimg'>
              <h1 className='titles'>Informatik</h1>
              <p className='subtitles'>Probleme zu lösen und sich zu hinterfragen, wie Systeme aufgebaut sind und wie sie funktionieren hat mich schon immer interessiert. 
              Ich finde es spannend laufend neue Dinge zu lernen, stets mit den neusten Entwicklungen up to date zu sein und selbst Applikationen programmieren zu können.</p>
            </article>
          </div>
          
          <div class="col-50">
            <img className='sectionimg' src="./images/code.jpg" alt="image"/>
          </div>
          <div class="col-50 desktopimg">
            <article className='articleimg'>
              <h1 className='titles'>Informatik</h1>
              <p className='subtitles'>Probleme zu lösen und sich zu hinterfragen, wie Systeme aufgebaut sind und wie sie funktionieren hat mich schon immer interessiert. 
              Ich finde es spannend laufend neue Dinge zu lernen, stets mit den neusten Entwicklungen up to date zu sein und selbst Applikationen programmieren zu können.</p>
            </article>
          </div>
        </div>
      </section>


      <section className='img-text mb-5'>
        <div class="row align-items-center">
        <div class="col-50">
            <article className='articleimg'>
              <h1 className='titles'>Krafttraining</h1>
              <p className='subtitles'>Für mich ist das Krafttraining ein guter Ausgleich zu meiner Ausbildung. 
              Am Abend nach dem Feierabend tut es gut den Kopf einmal abschalten zu können und den eigenen Körper an seine Grenzen zu bringen. 
              Konstantes Trainieren ist für mich wichtig, um Fortschritte zu erzielen und diszipliniert zu bleiben. </p>
            </article>
          </div>
          <div class="col-50">
            <img className='sectionimg' src="./images/gymsquat.jpg" alt="image"/>
          </div>
        </div>
      </section>


      <section className='img-text'>
        <div class="row align-items-center">

        <div class="col-50 mobileimg">
            <article className='articleimg'>
              <h1 className='titles'>Reisen</h1>
              <p className='subtitles'>Neue Orte zu sehen, weckt Neugier in mir. 
              Ich mag es unbekannte Städte, Regionen zu entdecken und neues darüber zu erfahren. 
              Gerne plane ich mit meinen Freunden Ausflüge.</p>
            </article>
          </div>
          
          <div class="col-50">
            <img className='sectionimg' src="./images/reisen.jpg" alt="image"/>
          </div>
          <div class="col-50 desktopimg">
            <article className='articleimg'>
              <h1 className='titles'>Reisen</h1>
              <p className='subtitles'>Neue Orte zu sehen, weckt Neugier in mir. 
              Ich mag es unbekannte Städte, Regionen zu entdecken und neues darüber zu erfahren. 
              Gerne plane ich mit meinen Freunden Ausflüge.</p>
            </article>
          </div>
        </div>
      </section>
      

        
     
    </>
  )
}

export default Hobbys
