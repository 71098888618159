import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import './Footer.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

const Footer = () => {
  return (
    <>
    <div className='body'>
        <footer class="footer">
            <div class="container">
            <div class="row">
                <div class="footer-col">
                <h4>Über mich</h4>
                <ul class="ul">
                    <li><Link to='/hobbys'>Hobbys/ Interessen</Link></li>
                    <li><Link to='/dokumente'>Dokumente</Link></li>
                    <li><Link to='/projekte'>Projekte</Link></li>
                    <li><Link to='/ferienjob'>Ferienjob</Link></li>
                </ul>
                </div>
                <div class="footer-col">
                    <h4>Links</h4>
                    <ul class="ul">
                        <li><a href="https://bwdbern.ch/" target="_blank">Bwd</a></li>
                        <li><a href="https://gibb.ch/" target="_blank">Gibb</a></li>
                        <li><a href="https://berufsbildungscenter.ch/" target="_blank">Bbc</a></li>
                        <li><a href="http://www.ufwaerts-aaretal.ch/" target="_blank">Ufwärts</a></li>
                    </ul>
                </div>
                <div class="footer-col">
                    <h4>Kontakt</h4>
                    <ul class="ul">
                        <li>Jonas Hafner</li>
                        <li><a href="mailto:hafner.jonas@gmx.ch">hafner.jonas@gmx.ch</a></li>
                        <li><a href="tel:0796285194">079 628 51 94</a></li>
                    </ul>
                </div>
                <div class="footer-col">
                    <h4>Rechtlich</h4>
                    <ul class="ul">
                        <li><Link to='/impressum'>Impressum</Link></li>
                    </ul>
                </div>
            </div>
            </div>
        </footer>
    </div>
    </>
  )
}

export default Footer
